/*----------------------------------------------------------------------------*
	$GLOBAL
	Carga todos los recursos requeridos y los compila en un único archivo
*----------------------------------------------------------------------------*/
/**
 *
 * == $load-grid ==
 * Definir en true para cargar la grilla.
 *
 */
@import url("vendors/icomoon.css");
.g-container-full {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.g-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .g-container {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 769px) {
  .g-container {
    max-width: 750px;
  }
}
@media only screen and (min-width: 1400px) {
  .g-container {
    max-width: 1044px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .g-container {
    width: calc(100% - 60px);
    max-width: none;
    margin-right: 30px;
    margin-left: 30px;
  }
}
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  vertical-align: baseline;
}
body {
  font-family: sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
ul,
ol {
  list-style: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $base3-font-family;
  font-weight: 700;
}
h1,
.h1 {
  font-size: 3.2rem;
}
h2,
.h2 {
  font-size: 2.5rem;
}
h3,
.h3 {
  font-size: 1.9rem;
}
h4,
.h4 {
  font-size: 1.6rem;
}
h5,
.h5 {
  font-size: 1.4rem;
}
h6,
.h6 {
  font-size: 1.2rem;
}
p {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
}
p:last-child {
  margin-bottom: 0;
}
b,
strong {
  color: #474c51;
}
a {
  outline: none;
  text-decoration: none;
  color: #db251d;
}
a:hover,
a:focus {
  color: #991a14;
}
img {
  max-width: 100%;
  height: auto;
  border: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
button,
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  font-size: 10px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
  color: #666d73;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.o-btn,
.o-btn-1,
.o-btn-2 {
  appearance: none;
  border: none;
  outline: 0;
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 0 25px;
  border-radius: 5px;
  background: #f7931e;
  font-weight: 700;
  font-size: 1.7rem;
  color: #fff;
  transition: all 0.3s ease;
}
.o-btn:hover,
.o-btn-1:hover,
.o-btn-2:hover {
  background: #44494b;
  color: #fff;
}
.o-btn [class*="icon"],
.o-btn-1 [class*="icon"],
.o-btn-2 [class*="icon"] {
  margin-left: 8px;
  padding-top: 5px;
  font-size: 2rem;
}
.icon-der {
  padding: 0 12px 4px 15px;
}
.icon-iz {
  padding: 0 15px 4px 12px;
}
.icon-iz [class*="icon"] {
  margin-left: 0;
  margin-right: 8px;
}
.o-btn-1 {
  border-color: #f7931e;
  color: #f7931e;
}
.o-btn-1:hover {
  background: #f7931e;
}
.o-btn-2 {
  border-color: #ff8d21;
  color: #ff8d21;
}
.o-btn-2:hover {
  background: #ff8d21;
}
.o-btn-cerrar {
  appearance: none;
  border: none;
  outline: 0;
  position: absolute;
  top: -25px;
  right: -25px;
  padding: 0;
  background: transparent;
  font-size: 2.5rem;
  color: #fff;
}
@media only screen and (max-width: 480px) {
  .o-btn-cerrar {
    top: -35px;
    left: 50%;
    margin-left: -1.25rem;
    right: auto;
  }
}
.o-btn-cerrar span {
  display: none;
}
.o-form-field,
.o-form-field--icon,
.o-form-field--textarea,
.o-form-field-2,
.selectize-input {
  appearance: none;
  width: 100%;
  height: 46px;
  padding: 0 16px;
  border: 1px solid #dddede;
  border-radius: 5px;
  outline: 0;
  background: #f7f7f7;
  font-size: 1.8rem;
  font-weight: 400;
  color: #666d73;
  transition: all 0.3s ease;
}
.o-form-field::-webkit-input-placeholder,
.o-form-field--icon::-webkit-input-placeholder,
.o-form-field--textarea::-webkit-input-placeholder,
.o-form-field-2::-webkit-input-placeholder,
.selectize-input::-webkit-input-placeholder {
  color: #53585b;
}
.o-form-field:-moz-placeholder,
.o-form-field--icon:-moz-placeholder,
.o-form-field--textarea:-moz-placeholder,
.o-form-field-2:-moz-placeholder,
.selectize-input:-moz-placeholder {
  opacity: 1;
  color: #53585b;
}
.o-form-field::-moz-placeholder,
.o-form-field--icon::-moz-placeholder,
.o-form-field--textarea::-moz-placeholder,
.o-form-field-2::-moz-placeholder,
.selectize-input::-moz-placeholder {
  opacity: 1;
  color: #53585b;
}
.o-form-field:-ms-input-placeholder,
.o-form-field--icon:-ms-input-placeholder,
.o-form-field--textarea:-ms-input-placeholder,
.o-form-field-2:-ms-input-placeholder,
.selectize-input:-ms-input-placeholder {
  color: #53585b;
}
.o-form-field:hover,
.o-form-field--icon:hover,
.o-form-field--textarea:hover,
.o-form-field-2:hover,
.selectize-input:hover {
  border-color: #bcc1c1;
}
.o-form-field:focus,
.o-form-field--icon:focus,
.o-form-field--textarea:focus,
.o-form-field-2:focus,
.selectize-input:focus {
  background: #fff;
  color: #666d73;
}
.o-form-field--icon {
  border-radius: 0 5px 5px 0;
}
.o-form-field--textarea {
  height: 150px;
  padding: 13px;
}
.o-form-field-2 {
  height: 36px;
  border-color: #cd2b24;
  background: #991912;
  font-size: 1.7rem;
  color: #fff;
}
.o-form-field-2::-webkit-input-placeholder {
  color: #fff;
}
.o-form-field-2:-moz-placeholder {
  opacity: 1;
  color: #fff;
}
.o-form-field-2::-moz-placeholder {
  opacity: 1;
  color: #fff;
}
.o-form-field-2:-ms-input-placeholder {
  color: #fff;
}
.o-form-field-2:hover {
  border-color: #cd2b24;
}
.o-form-field-2:focus {
  background: #cd2b24;
  color: #fff;
}
.o-form-check {
  display: flex;
}
.o-form-check__campo {
  display: none;
}
.o-form-check__campo + label,
.o-form-check__campo + span + label {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  font-family: 'icomoon' !important;
  font-size: 2rem;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9ca1a5;
  cursor: pointer;
}
.o-form-check__campo + label + span,
.o-form-check__campo + span + label + span {
  padding-top: 1px;
  font-size: 1.6rem;
  color: #f7931e;
  font-weight: 500;
}
.o-form-check__campo + label:hover,
.o-form-check__campo + span + label:hover {
  color: #81888d;
}
.o-form-check__campo[type="checkbox"] + label:before,
.o-form-check__campo[type="checkbox"] + span + label:before {
  content: "\ee6f";
}
.o-form-check__campo[type="radio"] + label:before,
.o-form-check__campo[type="radio"] + span + label:before {
  content: "\ee73";
}
.o-form-check__campo:checked + label {
  color: #db251d;
}
.o-form-check__campo:checked + label:hover:before {
  color: #c02019;
}
.o-form-check__campo:checked[type="checkbox"] + label:before {
  content: "\ee6e";
}
.o-form-check__campo:checked[type="radio"] + label:before {
  content: "\ee76";
}
.selectize-input {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-flex !important;
  align-items: center;
  padding: 0 30px 0 20px;
}
.selectize-input > input {
  width: 100% !important;
  padding-bottom: 4px;
  border: none !important;
  font-size: 1.8rem !important;
  font-weight: 400 !important;
  color: #666d73 !important;
}
.selectize-input > input::-webkit-input-placeholder {
  color: #94999d;
}
.selectize-input > input:-moz-placeholder {
  opacity: 1;
  color: #94999d;
}
.selectize-input > input::-moz-placeholder {
  opacity: 1;
  color: #94999d;
}
.selectize-input > input:-ms-input-placeholder {
  color: #94999d;
}
.selectize-input > .item {
  flex: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: 3px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #666d73;
}
.selectize-dropdown {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: calc(100% + 2px) !important;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.2);
  background: #fff;
  font-size: 1.5rem;
}
.selectize-dropdown .active {
  background-color: #f2f4f6;
}
.o-form-link-1 {
  font-size: 1.7rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease;
}
.o-form-link-1:hover {
  color: #f7931e;
}
span.o-form-error {
  display: block;
  padding-left: 20px;
  font-size: 1.5rem;
  color: #db251d;
}
.o-form-group {
  display: flex;
}
.o-form-group__icon {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 5px 0 0 5px;
  background: #53585b;
  font-size: 2rem;
  color: #fff;
}
.o-heading-small,
.o-heading-small--center {
  font-family: $base3-font-family;
  font-size: 0.9rem;
  font-weight: 700;
}
.o-heading-small--center {
  text-align: center;
}
.o-heading-normal,
.o-heading-normal--center {
  font-family: $base3-font-family;
  font-size: 1.6rem;
  font-weight: 700;
}
.o-heading-normal--center {
  text-align: center;
}
.o-heading-medium,
.o-heading-medium--center {
  font-family: $base3-font-family;
  font-size: 2.4rem;
  font-weight: 700;
}
.o-heading-medium--center {
  text-align: center;
}
.o-heading-big,
.o-heading-big--center {
  font-family: $base3-font-family;
  font-size: 3.2rem;
  font-weight: 700;
}
.o-heading-big--center {
  text-align: center;
}
.o-heading-super-big,
.o-heading-super-big--center {
  font-family: $base3-font-family;
  font-size: 5.6rem;
  font-weight: 700;
}
.o-heading-super-big--center {
  text-align: center;
}
.o-player-wrap {
  width: 100%;
  position: relative;
  padding: 56.25% 0 0 0;
  box-shadow: 0 2px 20px rgba(0,0,0,0.7);
}
.o-player-wrap iframe {
  position: absolute;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
}
.o-player-video {
  position: relative;
  width: 100% !important;
  border-radius: 7px;
  padding: 58% 0 0 0;
  background: rgba(12,74,56,0.8);
}
.o-player-video:before {
  position: absolute;
  top: 50%;
  left: 50%;
  color: $base4-color;
  font-size: 7rem;
  transform: translate(-50%, -50%);
}
.o-text-icon {
  display: flex;
  align-items: center;
}
.o-text-icon:before {
  margin-right: 10px;
  font-size: 2.2rem;
}
.c-notificacion__estado,
.c-notificacion--ok .c-notificacion__estado,
.c-notificacion--ok-nota .c-notificacion__estado,
.c-notificacion--error .c-notificacion__estado {
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-weight: $meidum;
}
.c-notificacion__estado [class*="icon"],
.c-notificacion--ok .c-notificacion__estado [class*="icon"],
.c-notificacion--ok-nota .c-notificacion__estado [class*="icon"],
.c-notificacion--error .c-notificacion__estado [class*="icon"] {
  margin-right: 7px;
}
.c-notificacion--ok .c-notificacion__estado,
.c-notificacion--ok-nota .c-notificacion__estado {
  border: 1px solid #5dd281;
  background: #b7edc9;
  color: #257c59;
}
.c-notificacion--ok-nota .c-notificacion__estado {
  position: relative;
  border-radius: 5px 5px 0 0;
}
.c-notificacion--ok-nota .c-notificacion__estado:before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 15px;
  width: 0;
  height: 0;
  border-top: 7px solid #b7edc9;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.c-notificacion--ok-nota .c-notificacion__estado:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(100%);
  left: 13px;
  width: 0;
  height: 0;
  border-top: 9px solid #5dd281;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}
.c-notificacion--error .c-notificacion__estado {
  border: 1px solid #f6d167;
  background: #f9edb8;
  color: #c26011;
}
.c-notificacion__nota {
  padding: 12px 15px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #cbcbcb;
  background: #fff;
  font-size: 1.4rem;
}
.c-block-login {
  height: 100vh;
  background: url("../images/bg-login.jpg") no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 481px) {
  .c-block-login {
    display: flex;
    align-items: center;
  }
}
.c-block-login__container {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .c-block-login__container {
    padding: 30px 15px;
  }
}
.c-block-login__logo {
  margin-bottom: 30px;
}
.c-block-login__logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.c-block-login__box {
  max-width: 360px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  background: #fff;
  overflow: hidden;
}
.c-block-login__main {
  padding: 25px;
}
.c-block-login__header {
  margin-bottom: 18px;
}
.c-block-login__icon-perfil {
  display: block;
  margin: 0 auto 10px auto;
}
.c-block-login__title {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}
.c-block-login__footer {
  padding: 18px;
  background: #53585b;
  text-align: center;
}
.c-form-login__campo {
  margin-bottom: 12px;
}
.c-form-login__campo--btn {
  padding-top: 9px;
  text-align: center;
}
.reproductor {
  width: 100%;
  position: relative;
  padding: 56.25% 0 0 0;
}
.reproductor iframe,
.reproductor img {
  position: absolute;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 15px;
  font-weight: 400;
  color: #484e53;
}
p {
  line-height: 1.4;
}
p [class*="btn"] {
  margin-right: 12px;
}
.justify {
  text-align: justify;
}
.img-iz {
  display: block;
  margin-bottom: 20px;
}
@media only screen and (min-width: 601px) {
  .img-iz {
    float: left;
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 10px;
  }
}
.img-der {
  display: block;
  margin-bottom: 20px;
}
@media only screen and (min-width: 601px) {
  .img-der {
    float: right;
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-bottom: 10px;
  }
}
.img-full {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.block-content,
.block-content--center {
  margin-bottom: 30px;
}
.block-content:before,
.block-content--center:before,
.block-content:after,
.block-content--center:after {
  content: "";
  display: table;
}
.block-content:after,
.block-content--center:after {
  clear: both;
}
@media only screen and (max-width: 768px) {
  .block-content,
  .block-content--center {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.block-content:last-child,
.block-content--center:last-child {
  margin-bottom: 0;
}
.block-content--center > * {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.block-columnas {
  margin-bottom: 30px;
}
.block-columnas:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 601px) {
  .block-columnas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 768px) {
  .block-columnas {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 601px) {
  .col-1-2 {
    flex-basis: calc(100% * 0.5 - 1.5rem);
    max-width: calc(100% * 0.5 - 1.5rem);
  }
}
@media only screen and (max-width: 600px) {
  .col-1-2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 601px) {
  .col-1-3 {
    flex-basis: calc(100% * 0.333333333333333 - 2rem);
    max-width: calc(100% * 0.333333333333333 - 2rem);
  }
}
@media only screen and (max-width: 600px) {
  .col-1-3 {
    margin-bottom: 20px;
  }
}
.espacio {
  width: 100%;
  height: 50px;
}
.center {
  text-align: center;
}
.media-content,
.media-content--radius {
  margin-bottom: 20px;
}
.media-content img,
.media-content--radius img {
  display: block;
}
.media-content--radius img {
  max-width: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.separador-cms {
  width: 100%;
  height: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e0e6ec;
}
.titulo-notifica {
  color: #fff;
}
.area-cms {
  width: 100%;
}
.btn {
  appearance: none;
  border: none;
  outline: 0;
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 25px;
  border-radius: 20px;
  background: #db251d;
  color: #fff;
  transition: all 0.3s ease;
}
.btn:hover {
  background: #f7931e;
  color: #fff;
}
.mfp-content {
  padding-bottom: 30px !important;
}
.mfp-bg.mfp-popUp {
  background: #5b1f1f !important;
}
.menu-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(91,31,31,0.9);
  overflow: hidden;
  z-index: 100;
}
.loader-popup {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
}
.loader__box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loader__box [class*="icon"] {
  width: 30px;
  height: 30px;
  font-size: 3rem;
  line-height: 1;
  color: #fff;
}
.animate-spin {
  animation: animate-spin 0.6s infinite linear;
}
.c-popup-open {
  overflow: hidden !important;
}
.c-popup,
.c-popup--small-1 {
  display: block;
  position: relative;
  margin: 50px auto 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0,0,0,0.7);
}
@media only screen and (max-width: 480px) {
  .c-popup,
  .c-popup--small-1 {
    width: 90%;
  }
}
@media only screen and (max-width: 380px) {
  .c-popup,
  .c-popup--small-1 {
    width: 100%;
  }
}
.c-popup__header {
  padding: 15px 35px;
  border-radius: 8px 8px 0 0;
  background: #db251d;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
}
.c-popup__container {
  padding: 25px;
  border-radius: 0 0 8px 8px;
  background: #fff;
}
.c-popup--small-1 {
  max-width: 350px;
}
@-moz-keyframes animate-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes animate-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-o-keyframes animate-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes animate-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.is-hidden {
  display: none;
}
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0,0,0,0.06) !important;
  border: 0 none !important;
  -webkit-box-shadow: inset 0 0 12px 4px #fff;
  box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: '!';
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.selectize-dropdown-header {
  position: relative;
  padding: 5px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.selectize-dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #303030;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
}
.selectize-dropdown-header-close:hover {
  color: #000;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}
.selectize-control.plugin-remove_button [data-value] {
  position: relative;
  padding-right: 24px !important;
}
.selectize-control.plugin-remove_button [data-value] .remove {
  z-index: 1;
/* fixes ie bug (see #392) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 17px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 0 0 0;
  border-left: 1px solid #d0d0d0;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.selectize-control.plugin-remove_button [data-value] .remove:hover {
  background: rgba(0,0,0,0.05);
}
.selectize-control.plugin-remove_button [data-value].active .remove {
  border-left-color: #cacaca;
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove {
  border-left-color: #fff;
}
.selectize-control.plugin-remove_button .remove-single {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 23px;
}
.selectize-control {
  position: relative;
}
.selectize-dropdown,
.selectize-input,
.selectize-input input {
  font-family: inherit;
  -webkit-font-smoothing: inherit;
}
.selectize-input,
.selectize-control.single .selectize-input.input-active {
  background: #fff;
  cursor: text;
  display: inline-block;
}
.selectize-control.multi .selectize-input.has-items {
  padding: 6px 8px 3px;
}
.selectize-input.full {
  background-color: #fff;
}
.selectize-input.disabled,
.selectize-input.disabled * {
  cursor: default !important;
}
.selectize-input > * {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.selectize-control.multi .selectize-input > div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
  background: #f2f2f2;
  color: #303030;
  border: 0 solid #d0d0d0;
}
.selectize-control.multi .selectize-input > div.active {
  background: #e8e8e8;
  color: #303030;
  border: 0 solid #cacaca;
}
.selectize-control.multi .selectize-input.disabled > div,
.selectize-control.multi .selectize-input.disabled > div.active {
  color: #7d7d7d;
  background: #fff;
  border: 0 solid #fff;
}
.selectize-input > input::-ms-clear {
  display: none;
}
.selectize-input > input:focus {
  outline: none !important;
}
.selectize-input::after {
  content: ' ';
  display: block;
  clear: left;
}
.selectize-input.dropdown-active::before {
  content: ' ';
  display: block;
  position: absolute;
  background: #f0f0f0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.selectize-dropdown [data-selectable] .highlight {
  background: rgba(125,168,208,0.2);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.selectize-dropdown .option,
.selectize-dropdown .optgroup-header {
  padding: 5px 8px;
}
.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: 0.5;
}
.selectize-dropdown [data-selectable].option {
  opacity: 1;
}
.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.selectize-dropdown .optgroup-header {
  color: #303030;
  background: #fff;
  cursor: default;
}
.selectize-dropdown .active.create {
  color: #495c68;
}
.selectize-dropdown .create {
  color: rgba(48,48,48,0.5);
}
.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
}
.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}
.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input {
  cursor: text;
}
.selectize-control.single .selectize-input:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #808080 transparent transparent transparent;
}
.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: -4px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #808080 transparent;
}
.selectize-control.rtl.single .selectize-input:after {
  left: 15px;
  right: auto;
}
.selectize-control.rtl .selectize-input > input {
  margin: 0 4px 0 -2px !important;
}
.selectize-control .selectize-input.disabled {
  opacity: 0.5;
  background-color: #fafafa;
}
