/*----------------------------------------------------------------------------*
	$ALERTS
*----------------------------------------------------------------------------*/


/* ^Notificaciones
-----------------------------------------------------------------------------*/

.c-notificacion__estado
	display: flex
	width: 100%
	padding: 15px
	border-radius: 5px
	font-weight: $meidum

	[class*="icon"]
		margin-right: 7px


.c-notificacion--ok
	.c-notificacion__estado
		@extend .c-notificacion__estado
		border: 1px solid #5dd281
		background: #b7edc9
		color: #257c59


.c-notificacion--ok-nota
	@extend .c-notificacion--ok

	.c-notificacion__estado
		position: relative
		border-radius: 5px 5px 0 0

		&:before
			content: ''
			position: absolute
			z-index: 2
			top: 100%
			left: 15px
			width: 0
			height: 0
			border-top: 7px solid #b7edc9
			border-left: 7px solid transparent
			border-right: 7px solid transparent

		&:after
			content: ''
			position: absolute
			z-index: 1
			top: calc(100%)
			left: 13px
			width: 0
			height: 0
			border-top: 9px solid #5dd281
			border-left: 9px solid transparent
			border-right: 9px solid transparent


.c-notificacion--error
	.c-notificacion__estado
		@extend .c-notificacion__estado
		border: 1px solid #f6d167
		background: #f9edb8
		color: #C26011


.c-notificacion__nota
	padding: 12px 15px
	border-radius: 0 0 5px 5px
	border: 1px solid #cbcbcb
	background: #fff
	font-size: 1.4rem

/* ^Fin - Notificaciones
-----------------------------------------------------------------------------*/
