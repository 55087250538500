/*----------------------------------------------------------------------------*
	$HEADING
*----------------------------------------------------------------------------*/


for key, val in $text-size

	.o-heading-{key}
		font-family: $header-font-family
		font-size: val
		font-weight: $bold

	.o-heading-{key}--center
		@extend .o-heading-{key}
		text-align: center
