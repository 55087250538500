/*----------------------------------------------------------------------------*
	$BUTTONS
*----------------------------------------------------------------------------*/


/* ^btn
-----------------------------------------------------------------------------*/

// Boton por defecto
.o-btn
	btn()
	display: inline-flex
	align-items: center
	height: 50px
	padding: 0 25px
	border-radius: 5px
	background: $base2-color
	font-weight: $bold
	font-size: 1.7rem
	color: #fff
	transition: all .3s ease
	// box-shadow: 0 3px 0 0 rgba(#000, .4)

	&:hover
		background: #44494b
		color: #fff

	[class*="icon"]
		margin-left: 8px
		padding-top: 5px
		font-size: 2rem


.icon-der
	padding: 0 12px 4px 15px

.icon-iz
	padding: 0 15px 4px 12px

	[class*="icon"]
		margin-left: 0
		margin-right: 8px




/* ^Fin - btn
-----------------------------------------------------------------------------*/





/* ^.o-btn-1
-----------------------------------------------------------------------------*/

.o-btn-1
	@extend .o-btn
	border-color: $base2-color
	color: $base2-color

	&:hover
		background: $base2-color

/* ^Fin - .o-btn-1
-----------------------------------------------------------------------------*/





/* ^.o-btn-2
-----------------------------------------------------------------------------*/

.o-btn-2
	@extend .o-btn
	border-color: #FF8D21
	color: #FF8D21

	&:hover
		background: #FF8D21


/* ^Fin - .o-btn-2
-----------------------------------------------------------------------------*/





/*	^o-btn-cerrar 'cerrar popUps'
-------------------------------------------------------------*/

.o-btn-cerrar
	btn()
	position: absolute
	top: -25px
	right: -25px
	padding: 0
	background: transparent
	font-size: 2.5rem
	color: #fff

	+max('m')
		top: -35px
		left: 50%
		margin-left: -1.25rem
		right: auto

	span
		display: none

/*	^Fin - o-btn-cerrar 'cerrar popUps'
-------------------------------------------------------------*/
