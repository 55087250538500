/*----------------------------------------------------------------------------*
	$C-BLOCK
*----------------------------------------------------------------------------*/


/* ^.c-block-login
-----------------------------------------------------------------------------*/

.c-block-login
	height: 100vh
	background: url($img-path + 'bg-login.jpg') no-repeat
	background-size: cover

	+min('m')
		display: flex
		align-items: center



.c-block-login__container
	width: 100%

	+max('m')
		padding: 30px 15px

.c-block-login__logo
	margin-bottom: 30px

	img
		display: block
		margin-left: auto
		margin-right: auto


.c-block-login__box
	max-width: 360px
	margin: 0 auto
	border-radius: 5px
	box-shadow: 0 2px 10px 0 rgba(#000, .10)
	background: #fff
	overflow: hidden


.c-block-login__main
	padding: 25px


.c-block-login__header
	margin-bottom: 18px


.c-block-login__icon-perfil
	display: block
	margin: 0 auto 10px auto


.c-block-login__title
	text-align: center
	font-weight: $semi-bold
	font-size: 2rem


.c-block-login__footer
	padding: 18px
	background: #53585B
	text-align: center



.c-form-login__campo
	margin-bottom: 12px


.c-form-login__campo--btn
	padding-top: 9px
	text-align: center

/* ^Fin - .c-block-login
-----------------------------------------------------------------------------*/
