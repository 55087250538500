/*----------------------------------------------------------------------------*
	$BLOCKS-CMS
*----------------------------------------------------------------------------*/



/* ^Reproductor video
-----------------------------------------------------------------------------*/

.reproductor
	width: 100%
	position: relative
	padding: 56.25% 0 0 0

	iframe,
	img
		position: absolute
		width: 100% !important
		height: 100%
		top:0
		left: 0

/* ^Fin - Reproductor video
-----------------------------------------------------------------------------*/



/* ^Titulos
-----------------------------------------------------------------------------*/

h1, .h1,
h2, .h2,
h3, .h3,
h4,	.h4,
h5, .h5,
h6, .h6
	margin-bottom: 15px
	font-weight: $normal
	color: #484E53

/* ^Fin - Titulos
-----------------------------------------------------------------------------*/




/* ^Parrafos
-----------------------------------------------------------------------------*/

p
	line-height: 1.4

	[class*="btn"]
		margin-right: 12px


.justify
	text-align: justify

/* ^Fin - Parrafos
-----------------------------------------------------------------------------*/




/* ^Imagenes
-----------------------------------------------------------------------------*/

.img-iz
	display: block
	margin-bottom: 20px

	+min(601px)
		float: left
		width: calc(50% - 30px)
		margin-right: 30px
		margin-bottom: 10px

.img-der
	display: block
	margin-bottom: 20px

	+min(601px)
		float: right
		width: calc(50% - 30px)
		margin-left: 30px
		margin-bottom: 10px

.img-full
	display: block
	width: 100%
	margin-bottom: 20px


/* ^Fin - Imagenes
-----------------------------------------------------------------------------*/




/* ^Bloques
-----------------------------------------------------------------------------*/

.block-content
	clearfix()
	margin-bottom: 30px

	+max('l')
		padding-left: 20px
		padding-right: 20px

	&:last-child
		margin-bottom: 0

.block-content--center
	@extend .block-content

	> *
		max-width: 550px
		margin-left: auto
		margin-right: auto
		text-align: center

.block-columnas
	margin-bottom: 30px

	&:last-child
		margin-bottom: 0

	+min(601px)
		grid()

	+max('l')
		padding-left: 20px
		padding-right: 20px

.col-1-2
	+min(601px)
		col(1,2)

	+max(600px)
		margin-bottom: 20px

.col-1-3
	+min(601px)
		col(1,3)

	+max(600px)
		margin-bottom: 20px


/* ^Fin - Bloques
-----------------------------------------------------------------------------*/





/* ^General
-----------------------------------------------------------------------------*/

.espacio
	width: 100%
	height: 50px

.center
	text-align: center


.media-content
	margin-bottom: 20px

	img
		display: block

.media-content--radius
	@extend .media-content

	img
		max-width: 200px
		border-radius: 50%
		margin-left: auto
		margin-right: auto

.separador-cms
	width: 100%
	height: 25px
	margin-bottom: @height
	border-bottom: 1px solid #e0e6ec

.titulo-notifica
	color: #fff

.area-cms
	width: 100%

/* ^Fin - General
-----------------------------------------------------------------------------*/
