/*----------------------------------------------------------------------------*
	$BUTTONS
*----------------------------------------------------------------------------*/


/* ^btn
-----------------------------------------------------------------------------*/

// Boton por defecto
.btn
	btn()
	display: inline-flex
	align-items: center
	height: 40px
	padding: 0 25px
	border-radius: (@height / 2)
	background: $base-color
	color: #fff
	transition: all .3s ease

	&:hover
		background: $base2-color
		color: #fff

/* ^Fin - btn
-----------------------------------------------------------------------------*/
