/*----------------------------------------------------------------------------*
	$HEADER
*----------------------------------------------------------------------------*/

html
	font-size: $base-font-size

body
	font-family: $base-font-family
	font-size: $text-size.normal
	font-weight: $base-font-weight
	line-height: $base-line-height
	color: $base-text-color
	font-smoothing()
	overflow-x: hidden
