/*----------------------------------------------------------------------------*
	$TEXTS
	Este archivo contiene todo lo relacionado con los estilos para elementos
	de tipografía a nivel global.
*----------------------------------------------------------------------------*/


/* ^Encabezados
-----------------------------------------------------------------------------*/

h1, .h1,
h2, .h2,
h3, .h3,
h4,	.h4,
h5, .h5,
h6, .h6
	font-family: $header-font-family
	font-weight: $header-font-weight


h1,
.h1
	font-size: $header-font-size.h1

h2,
.h2
	font-size: $header-font-size.h2

h3,
.h3
	font-size: $header-font-size.h3

h4,
.h4
	font-size: $header-font-size.h4

h5,
.h5
	font-size: $header-font-size.h5

h6,
.h6
	font-size: $header-font-size.h6

/* ^Fin - Encabezados
-----------------------------------------------------------------------------*/





/* ^Parrafos
-----------------------------------------------------------------------------*/

p
	margin-bottom: $p-margin-bottom
	font-size: $p-font-size

	&:last-child
		margin-bottom: 0

b,
strong
	color: $base-text-color-bold

/* ^Fin - Parrafos
-----------------------------------------------------------------------------*/






/* ^Enlaces (links)
-----------------------------------------------------------------------------*/

/**
 *
 * Mostrar por defecto los enlaces sin el subrayado. Por otro lado se cambia
 * el color por defecto de los enlaces.
 *
 */
a
	outline: none
	text-decoration: none
	color: $base-link-color

	&:hover,
	&:focus
		color: $base-link-color-hover

/* ^Fin - Enlaces (links)
-----------------------------------------------------------------------------*/
